<template>
    <div class="pt-7 container-fluid">
        <b-card>
            <b-button variant="link" class="text-primary refresh" v-b-tooltip.hover.bottom :title="$t('general.refresh')" @click="getData">
                <i class="fas fa-sync-alt"></i>
            </b-button>
            <b-row class="filterSection">
                <b-col md="4" v-if="userType=='admin'">
                    <base-input :label="$t('general.branch')">
                        <el-select v-model="filter.branch" filterable="" clearable :placeholder="$t('general.select')" clearable @change="getData(true)">
                            <el-option v-for="(item, key) in branchList" :key="key" :label="item.name[$i18n.locale]" :value="item._id"></el-option>
                        </el-select>
                    </base-input>
                </b-col>
                <b-col md="4" v-if="userType=='admin'">
                    <base-input :label="$t('availability.doctor_list')">
                        <el-select v-model="filter.doctor" clearable filterable="" :placeholder="$t('general.select')" :no-match-text="$t('warning.no_match')" :no-data-text="$t('warning.no_data')" @change="getData(true)">
                            <el-option v-for="(item, index) in doctors" :key="index" :label="item.title+item.name+' - '+item.email+' - '+item.phone" :value="item.uuid"></el-option>
                        </el-select>
                    </base-input>
                </b-col>
                <b-col md="4">
                    <base-input :label="$t('page.publish_status')">
                        <el-select v-model="filter.published" clearable :placeholder="$t('general.select')" :no-match-text="$t('warning.no_match')" :no-data-text="$t('warning.no_data')" @change="getData(true)">
                            <el-option :label="$t('general.publish')" :value="true"></el-option>
                            <el-option :label="$t('general.not_publish')" :value="false"></el-option>
                        </el-select>
                    </base-input>
                </b-col>
                <b-col md="4">
                    <base-input :label="$t('page.answer_status')">
                        <el-select v-model="filter.answer" clearable :placeholder="$t('general.select')" :no-match-text="$t('warning.no_match')" :no-data-text="$t('warning.no_data')" @change="getData(true)">
                            <el-option :label="$t('general.answered')" :value="true"></el-option>
                            <el-option :label="$t('general.wait_answer')" :value="false"></el-option>
                        </el-select>
                    </base-input>
                </b-col>
                <b-col md="4">
                    <base-input type="text" :label="$t('general.patient_search_area')" :placeholder="$t('doctor.search_area')" v-model="filter.text" @input="searchTimeOut">
                    </base-input>
                </b-col>
            </b-row>
        </b-card>
        <b-card no-body class="p-4">
            <el-table class="table-responsive table" header-row-class-name="thead-light" :data="questions" v-loading='loading' style="width: 100%" :empty-text="$t('general.empty_table')">
                <el-table-column :label="$t('general.branch')" width="180">
                    <template v-slot="{row}">
                        {{row.branch | showBranch}}
                    </template>
                </el-table-column>
                <el-table-column :label="$t('general.doctor')" width="240">
                    <template v-slot="{row}">
                        {{row.doctor.title}} {{row.doctor.name}}
                    </template>
                </el-table-column>
                <el-table-column :label="$t('general.patient')" width="240">
                    <template v-slot="{row}">
                        {{row.patient.name}}
                    </template>
                </el-table-column>
                <el-table-column :label="$t('page.answer_status')" width="180">
                    <template v-slot="{row}">
                        {{row.answered ? $t('general.answered') : $t('general.wait_answer')}}
                    </template>
                </el-table-column>
                <el-table-column :label="$t('page.publish_status')" width="180">
                    <template v-slot="{row}">
                        {{row.published ? $t('general.publish') : $t('general.not_publish')}}
                    </template>
                </el-table-column>
                <el-table-column :label="$t('general.question_details')" width="240">
                    <template v-slot="{row}">
                        <b-row id="details_row">
                            <b-col cols="4">
                                <span class="text-primary" v-b-tooltip.hover.bottom container="details_row" :title="$t('general.likes')" triggers="hover">
                                    <i class="far fa-thumbs-up"></i> <b>{{row.likes}}</b>
                                </span>
                            </b-col>
                            <b-col cols="4">
                                <span class="text-danger" v-b-tooltip.hover.bottom container="details_row" :title="$t('general.dislikes')" triggers="hover">
                                    <i class="far fa-thumbs-down"></i> <b>{{row.dislikes}}</b>
                                </span>
                            </b-col>
                            <b-col cols="4">
                                <span class="text-success" v-b-tooltip.hover.bottom container="details_row" :title="$t('general.views')" triggers="hover">
                                    <i class="far fa-eye"></i> <b>{{row.views}}</b>
                                </span>
                            </b-col>
                        </b-row>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('general.created_date')" prop="createdAt" width="210">
                    <template v-slot="{row}">
                        {{row.createdAt | getDate}}
                    </template>
                </el-table-column>
                <el-table-column :label="$t('general.answered_date')" prop="answered" width="210">
                    <template v-slot="{row}">
                        <span v-if="row.answered">
                            {{row.answered | getDate}}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column align="right" :min-width="userType=='admin'?320:225">
                    <template slot-scope="scope">
                        <base-button class="dinline" type="primary" size="sm" @click="showContent(scope.row)">
                            {{$t('general.question_content')}}</base-button>
                        <template v-if="userType=='admin'">
                            <base-button class="dinline" :type="scope.row.published?'warning':'success'" size="sm" @click="changePublish(scope.row)">{{scope.row.published?$t('comment.not_publish'):$t('comment.publish')}}</base-button>
                            <base-button class="dinline" type="danger" size="sm" @click="deleteQuestion(scope.row.uuid)" :disabled="scope.row.published">{{$t('general.delete')}}</base-button>
                        </template>
                    </template>
                </el-table-column>
            </el-table>
            <b-card-footer class="py-4 d-flex justify-content-end" v-if="pages>1&&!loading">
                <base-pagination v-model="pagination.page" :per-page="pagination.limit" :total="total" @change="changePage"></base-pagination>
            </b-card-footer>
        </b-card>

        <modal :show.sync="modal.status" :showClose="false" :backdropClose="false" :hideFooter="true" @close="modalClose" size="lg">
            <b-alert class="posrel" variant="secondary" show v-if="modal.data.uuid!=undefined">
                <h3>{{$t('general.expertise')}} : {{modal.data.branch | showBranch}}</h3>
                <hr class="my-2">
                <strong>{{modal.data.patient.name}}</strong>
                <p class="mt-2">
                    <h4>{{modal.data.title}}</h4>
                    <small>
                        {{modal.data.content}}
                    </small>
                </p>
                <small>{{modal.data.createdAt | getDate}}</small>
                <template v-if="modal.data.answers&&modal.data.status">
                    <div v-for="(item, index) in modal.data.answers" :key="'answer-'+index">
                        <hr class="mt-2">
                        <strong>{{item.who=='doctor'?modal.data.doctor.title+ ' ' +modal.data.doctor.name:modal.data.patient.name}}</strong><br>
                        <small v-html="$options.filters.getHtmlText(item.content)"></small><br><br>
                        <small>{{item.date | getDate}}</small>
                    </div>
                </template>
                <template v-if="userType=='doctor'&&modal.data.status">
                    <hr class="mt-2">
                    <validation-observer v-slot="{handleSubmit, errors, invalid}" ref="formValidator">
                        <b-form role="form" @submit.prevent="handleSubmit(onSubmitAnswer)" v-loading="modal.loading">
                            <validation-provider :rules="'required|min:10|max:750'" :name="$t('general.comment')" v-slot="{ errors, valid, invalid, validated }">
                                <b-form-group>
                                    <label>{{$t('general.answer')}}</label>
                                    <b-form-textarea :class="errors[0] ? 'is-invalid' : ''" :placeholder="$t('general.answer')" rows="5" no-resize required v-model="modal.formData.answer"></b-form-textarea>
                                    <div class="invalid-feedback" style="display: block" v-if="errors[0]">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </validation-provider>
                            <base-button type="primary" native-type="submit" :disabled="modal.loading || invalid" size="sm">{{$t('save')}}</base-button>
                        </b-form>
                    </validation-observer>
                </template>
            </b-alert>
            <hr class="my-2">
            <base-button :type="modal.data.published?'danger':'success'" size="sm" @click="changePublish(modal.data)" v-if="userType=='admin'">{{modal.data.published?$t('comment.not_publish'):$t('comment.publish')}}</base-button>
            <base-button type="link" class="text-danger ml-auto float-right" @click="modalClose">{{$t('general.close')}}</base-button>
        </modal>
    </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { DOCTOR_REQUEST } from '@/store/modules/define';
import { handleError } from '@/utils/func';
let self;

export default {
    computed: {
        ...mapGetters({
            userType: 'getUserType',
            doctors: 'getDoctors',
            branchList: 'getBranchList',
            questions: 'getQuestions',
            total: 'getQuestionTotal',
            pages: 'getQuestionPages'
        })
    },
    data() {
        return {
            loading: true,
            modal: {
                status: false,
                loading: false,
                data: {},
                formData:{
                    answer: ''
                }
            },
            pagination: {
                limit: 10,
                page: 1
            },
            filter: {
                text: ''
            },
            timer: null
        };
    },
    methods: {
        deleteQuestion(uuid){
            this.$swal({
                title: self.$i18n.t('quest.sure'),
                text: self.$i18n.t('quest.delete_info'),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#f5365c',
                confirmButtonText: self.$i18n.t('quest.delete'),
                cancelButtonText: self.$i18n.t('quest.cancel')
            }).then(result => {
                if (result.value) {
                    self.loading = true;
                    self.$store
                        .dispatch('QUESTION_DELETE', { uuid })
                        .then(resp => {
                            self.loading = false;
                            self.modalClose();
                            self.getData();
                            self.$notify({ type: 'success', message: self.$i18n.t('success.positive') });
                        })
                        .catch(err => {
                            self.loading = false;
                            handleError(self, err);
                        });
                }
            });
        },
        changePublish(row) {
            let uuid = row.uuid;
            let status = row.published !== true;

            this.$swal({
                title: self.$i18n.t('quest.sure'),
                text: self.$i18n.t('quest.change_question_publish'),
                icon: status ? 'success' : 'warning',
                showCancelButton: true,
                confirmButtonColor: status ? '#2dce89' : '#f5365c',
                confirmButtonText: self.$i18n.t('quest.approve_confirm'),
                cancelButtonText: self.$i18n.t('quest.cancel')
            }).then(result => {
                if (result.value) {
                    self.loading = true;
                    self.$store
                        .dispatch('QUESTION_UPDATE', { uuid, question: { published: status } })
                        .then(resp => {
                            self.loading = false;
                            self.modalClose();
                            self.getData();
                            self.$notify({ type: 'success', message: self.$i18n.t('success.positive') });
                        })
                        .catch(err => {
                            self.loading = false;
                            handleError(self, err);
                        });
                }
            });
        },
        modalClose() {
            self.modal = {
                status: false,
                loading: false,
                data: {},
                formData:{
                    answer: ''
                }
            };
        },
        showContent(row) {
            self.modal.data = _.cloneDeep(row);
            self.modal.status = true;
        },
        changePage(val) {
            self.loading = true;
            self.pagination.page = val;
            self.getData();
        },
        getDoctors() {
            self.$store.dispatch(DOCTOR_REQUEST, { pagination: { limit: 10000 }, filter: self.filter, fields: '-_id title uuid name email phone' }).catch(err => {
                handleError(self, err);
            });
        },
        getData(page = false) {
            if (page) {
                self.pagination.page = 1;
            }
            self.loading = true;
            self.$store
                .dispatch('QUESTION_LIST_REQUEST', { pagination: self.pagination, filter: self.filter })
                .catch(err => {
                    handleError(self, err);
                })
                .finally(() => {
                    self.loading = false;
                });
        },
        searchTimeOut() {
            if (self.timer) {
                clearTimeout(self.timer);
                self.timer = null;
            }
            self.timer = setTimeout(() => {
                self.getData(true);
            }, 800);
        },
        onSubmitAnswer() {
            self.modal.loading = true;
            let uuid = self.modal.data.uuid;
            let answer = _.escape(_.trim(_.cloneDeep(self.modal.formData.answer)));

            self.$store
                .dispatch('QUESTION_SEND_ANSWER', { uuid, question : {answer} })
                .then(resp => {
                    self.modal.loading = false;
                    self.modalClose();
                    self.getData();
                    self.$notify({ type: 'success', message: self.$i18n.t('success.positive') });
                })
                .catch(err => {
                    self.modal.loading = false;
                    handleError(self, err);
                });
        },
    },
    filters: {
        showBranch(value) {
            let lang = self.$i18n.locale;
            let branches = _.map(
                _.pickBy(self.branchList, item => {
                    return item.status && _.includes(value, item._id);
                }),
                item => {
                    return item.name[lang];
                }
            );

            return _.join(branches, '');
        }
    },
    created() {
        self = this;
        self.getDoctors();
        self.getData();
    }
};
</script>